import * as React from "react";

export function Icon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="#000000"
      xmlns="https://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.1625 5.2125C15.9 5.2125 15.7125 4.9875 15.7125 4.7625V3.45C15.7125 3.1875 15.9375 3 16.1625 3C16.425 3 16.6125 3.225 16.6125 3.45V4.725C16.6125 4.9875 16.425 5.2125 16.1625 5.2125ZM21.6 9.375H20.325C20.0625 9.375 19.875 9.1875 19.875 8.925C19.875 8.6625 20.0625 8.475 20.325 8.475H21.6C21.8625 8.475 22.05 8.6625 22.05 8.925C22.0875 9.1875 21.8625 9.375 21.6 9.375ZM19.2375 6.4125C19.125 6.4125 19.0125 6.375 18.9 6.2625C18.7125 6.075 18.7125 5.775 18.9 5.625L19.8 4.725C19.9875 4.5375 20.2875 4.5375 20.4375 4.725C20.625 4.9125 20.625 5.2125 20.4375 5.3625L19.5375 6.2625C19.5 6.375 19.35 6.4125 19.2375 6.4125V6.4125ZM20.1375 13.275C20.025 13.275 19.9125 13.2375 19.8 13.125L18.9 12.225C18.7125 12.0375 18.7125 11.7375 18.9 11.5875C19.0875 11.4 19.3875 11.4 19.5375 11.5875L20.4375 12.4875C20.625 12.675 20.625 12.975 20.4375 13.125C20.4 13.2375 20.25 13.275 20.1375 13.275V13.275ZM13.2375 6.45C13.125 6.45 13.0125 6.4125 12.9 6.3L12 5.4375C11.8125 5.25 11.8125 4.95 12 4.8C12.1875 4.6125 12.4875 4.6125 12.6375 4.8L13.5375 5.7C13.725 5.8875 13.725 6.1875 13.5375 6.3375C13.4625 6.4125 13.35 6.45 13.2375 6.45V6.45Z"
        fill="var(--icon-orange)"
      />
      <path
        d="M17.0999 21.675H5.99995C4.08745 21.675 2.51245 20.1 2.51245 18.1875C2.51245 16.6875 3.48745 15.3375 4.87495 14.8875V14.8125C4.87495 13.05 6.29995 11.625 8.06245 11.625C8.47495 11.625 8.88745 11.7 9.26245 11.8875C10.0875 10.6125 11.5124 9.86249 13.0499 9.86249C15.5624 9.86249 17.6249 11.925 17.6249 14.4375C17.6249 14.7 17.5874 14.9625 17.5499 15.225C19.1249 15.45 20.3624 16.8 20.3624 18.45C20.3249 20.2125 18.8999 21.675 17.0999 21.675V21.675ZM8.02495 12.6375C6.82495 12.6375 5.84995 13.6125 5.84995 14.8125C5.84995 14.925 5.84995 15.0375 5.88745 15.1875L5.96245 15.675L5.47495 15.75C4.31245 15.975 3.48745 17.025 3.48745 18.1875C3.48745 19.575 4.61245 20.7 5.99995 20.7H17.0999C18.3374 20.7 19.3499 19.6875 19.3499 18.45C19.3499 17.2125 18.3374 16.2 17.0999 16.2H16.9874L16.2374 16.2375L16.4625 15.525C16.575 15.1875 16.65 14.8125 16.65 14.4375C16.65 12.45 15.0375 10.875 13.0875 10.875C11.775 10.875 10.5375 11.625 9.93745 12.7875L9.67495 13.275L9.22495 13.0125C8.84995 12.7125 8.43745 12.6375 8.02495 12.6375V12.6375Z"
        fill="var(--icon-cloud)"
      />
      <path
        d="M16.725 5.99999C15.15 5.69999 13.65 6.74999 13.3875 8.32499C13.35 8.47499 13.35 8.58748 13.35 8.73748C13.3125 8.92498 13.4625 9.11248 13.6875 9.14998C14.1 9.18748 14.0625 8.77499 14.1375 8.51249C14.3625 7.34999 15.45 6.59999 16.6125 6.78749C17.775 7.01249 18.525 8.09999 18.3375 9.26249C18.3 9.52499 18.225 9.78749 18.075 10.0125C17.8875 10.275 17.5875 10.5 17.325 10.6875C17.1 10.8375 17.2125 11.175 17.475 11.2125C17.775 11.2875 18.0375 11.0625 18.2625 10.8375C18.525 10.6125 18.75 10.275 18.9 9.93748C18.975 9.74998 19.05 9.56248 19.0875 9.37498C19.35 7.76249 18.3 6.26249 16.725 5.99999V5.99999Z"
        fill="var(--icon-orange)"
      />
    </svg>
  );
}
