import * as React from "react";

export function Icon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="#000000"
      xmlns="https://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.6375 18.825H6.29998C4.19998 18.825 2.47498 17.1 2.47498 15C2.47498 13.35 3.56248 11.8875 5.09998 11.3625V11.25C5.09998 9.3375 6.67498 7.7625 8.58748 7.7625C9.07498 7.7625 9.52498 7.875 9.97498 8.0625C10.9125 6.675 12.45 5.8125 14.175 5.8125C16.95 5.8125 19.2 8.0625 19.2 10.8375C19.2 11.1375 19.1625 11.4375 19.125 11.7375C20.8875 11.9625 22.2375 13.4625 22.2375 15.2625C22.2 17.2125 20.5875 18.825 18.6375 18.825V18.825ZM8.58748 8.775C7.23748 8.775 6.11248 9.9 6.11248 11.25C6.11248 11.3625 6.11248 11.5125 6.14998 11.6625L6.22498 12.15L5.73748 12.225C4.42498 12.4875 3.44998 13.65 3.44998 15C3.44998 16.575 4.72498 17.8125 6.26248 17.8125H18.6C20.025 17.8125 21.15 16.65 21.15 15.2625C21.15 13.8375 19.9875 12.7125 18.6 12.7125H18.4875L17.7375 12.75L17.9625 12.0375C18.075 11.625 18.15 11.2125 18.15 10.8C18.15 8.5875 16.35 6.7875 14.1375 6.7875C12.6375 6.7875 11.2875 7.6125 10.575 8.925L10.3125 9.4125L9.86248 9.15C9.48748 8.8875 9.03748 8.775 8.58748 8.775V8.775Z"
        fill="var(--icon-cloud)"
      />
    </svg>
  );
}
