import * as React from "react";

export function Icon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="#000000"
      xmlns="https://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.075 21.825H5.66245C3.93745 21.825 2.51245 20.4 2.51245 18.675C2.51245 17.3625 3.29995 16.1625 4.53745 15.7125C4.57495 14.175 5.84995 12.9 7.46245 12.9C7.79995 12.9 8.13745 12.9375 8.39995 13.0875C9.14995 12.0375 10.3875 11.4 11.7 11.4C13.95 11.4 15.7875 13.2375 15.7875 15.4875C15.7875 15.675 15.7875 15.825 15.75 16.0125C17.0625 16.3125 18.1125 17.475 18.1125 18.9375C18.0375 20.5125 16.725 21.825 15.075 21.825ZM7.42495 14.1375C6.52495 14.1375 5.77495 14.8875 5.77495 15.7875C5.77495 15.9 5.77495 15.975 5.81245 16.0875L5.92495 16.6875L5.32495 16.8375C4.42495 17.025 3.78745 17.8125 3.78745 18.7125C3.78745 19.7625 4.64995 20.625 5.69995 20.625H15.1125C16.05 20.625 16.8 19.875 16.8 18.9375C16.8 18 16.05 17.25 15.1125 17.25H15L14.0625 17.2875L14.3625 16.3875C14.475 16.0875 14.5125 15.825 14.5125 15.4875C14.5125 13.95 13.2375 12.675 11.7 12.675C10.65 12.675 9.67495 13.2375 9.18745 14.175L8.84995 14.775L8.24995 14.475C8.02495 14.25 7.68745 14.1375 7.42495 14.1375Z"
        fill="var(--icon-cloud)"
      />
      <path
        d="M21.7875 9.26253C21.7875 9.26253 21.375 9.26253 21.1875 9.26253C19.3125 9.26253 17.5125 8.40002 16.575 7.05002C15.7875 5.88752 15.675 4.27502 16.2375 2.77502H16.125C14.5875 2.77502 13.0875 3.33752 12.0375 4.42502C10.95 5.51252 10.3125 7.01252 10.3125 8.58752C10.3125 9.07502 10.3875 9.60002 10.5 10.0875L10.5375 10.2375L10.6875 10.2C10.95 10.125 11.25 10.0875 11.55 10.05H11.7375L11.7 9.90002C11.5875 9.48752 11.5125 9.03753 11.5125 8.62503C11.5125 6.63752 12.8625 4.80002 14.8125 4.12502C14.625 5.66252 15.0375 7.12503 15.975 8.28753C16.9875 9.60003 18.5625 10.425 20.3625 10.6125C19.5375 12.1875 18.4875 13.0125 16.9875 13.275L16.8375 13.3125L16.9125 13.4625C17.025 13.725 17.1 13.9875 17.1375 14.25L17.175 14.4L17.325 14.3625C19.7625 13.875 21.6375 11.9625 21.975 9.52502L22.0125 9.37503L21.7875 9.26253Z"
        fill="var(--icon-moon)"
      />
    </svg>
  );
}
