import * as React from "react";

export function Icon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="#000000"
      xmlns="https://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.0875 9.03749C19.125 8.73749 19.1625 8.43749 19.1625 8.13749C19.1625 5.36249 16.9125 3.11249 14.1375 3.11249C12.45 3.11249 10.875 3.97499 9.9375 5.36249C9.4875 5.17499 9.0375 5.06249 8.55 5.06249C6.6375 5.06249 5.0625 6.59999 5.0625 8.54999V8.66249C3.525 9.18749 2.4375 10.6125 2.4375 12.3C2.4375 14.4 4.1625 16.125 6.2625 16.125H8.1375C8.7375 16.125 8.7 15.1125 8.1375 15.1125H6.2625C4.6875 15.1125 3.45 13.8375 3.45 12.3C3.45 10.95 4.3875 9.78749 5.7375 9.52499L6.225 9.41249L6.15 8.92499C6.1125 8.77499 6.1125 8.66249 6.1125 8.51249C6.1125 7.16249 7.2375 6.03749 8.5875 6.03749C9.0375 6.03749 9.4875 6.14999 9.9 6.41249L10.35 6.67499L10.6125 6.18749C11.325 4.87499 12.675 4.04999 14.175 4.04999C16.3875 4.04999 18.1875 5.84999 18.1875 8.06249C18.1875 8.47499 18.1125 8.88749 18 9.29999L17.775 10.0125L18.5625 9.97499H18.675C20.1 9.97499 21.225 11.1375 21.225 12.525C21.225 13.95 20.0625 15.075 18.675 15.075H16.5375C15.9 15.075 15.7875 16.0875 16.5375 16.0875H18.675C20.625 16.0875 22.2375 14.475 22.2375 12.525C22.2 10.7625 20.8125 9.26249 19.0875 9.03749V9.03749Z"
        fill="var(--icon-cloud)"
      />
      <path
        d="M9.33753 17.4375C9.22503 17.4375 9.15003 17.4 9.03753 17.3625C8.81253 17.2125 8.77503 16.875 8.92503 16.6875L9.82504 15.375C9.97504 15.15 10.275 15.1125 10.5 15.2625C10.725 15.4125 10.7625 15.7125 10.6125 15.9375L9.71253 17.25C9.63753 17.3625 9.48753 17.4375 9.33753 17.4375ZM11.5125 17.4375C11.4 17.4375 11.325 17.4 11.2125 17.3625C10.9875 17.2125 10.95 16.875 11.1 16.6875L12 15.375C12.15 15.15 12.4875 15.1125 12.675 15.2625C12.9 15.4125 12.9375 15.7125 12.7875 15.9375L11.8875 17.25C11.8125 17.3625 11.6625 17.4375 11.5125 17.4375ZM13.6875 17.4375C13.575 17.4375 13.5 17.4 13.3875 17.3625C13.1625 17.2125 13.125 16.875 13.275 16.6875L14.175 15.375C14.325 15.15 14.6625 15.1125 14.85 15.2625C15.075 15.4125 15.1125 15.7125 14.9625 15.9375L14.0625 17.25C14.025 17.3625 13.875 17.4375 13.6875 17.4375ZM6.97503 21C6.86253 21 6.78753 20.9625 6.67503 20.925C6.45003 20.775 6.41253 20.475 6.56253 20.25L7.46253 18.9375C7.61253 18.7125 7.95003 18.675 8.17503 18.825C8.40003 18.975 8.43753 19.275 8.28753 19.5L7.38753 20.8125C7.27503 20.925 7.12503 21 6.97503 21V21ZM9.18753 21C9.07503 21 9.00003 20.9625 8.88753 20.925C8.66253 20.775 8.62503 20.475 8.77503 20.25L9.67503 18.9375C9.82503 18.7125 10.125 18.675 10.35 18.825C10.575 18.975 10.6125 19.275 10.4625 19.5L9.56253 20.8125C9.48753 20.925 9.33753 21 9.18753 21ZM11.3625 21C11.25 21 11.175 20.9625 11.0625 20.925C10.8375 20.775 10.8 20.475 10.95 20.25L11.85 18.9375C12 18.7125 12.3 18.675 12.525 18.825C12.75 18.975 12.7875 19.275 12.6375 19.5L11.7375 20.8125C11.6625 20.925 11.5125 21 11.3625 21V21Z"
        fill="var(--icon-rain)"
      />
    </svg>
  );
}
