import * as React from "react";

export function Icon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="#000000"
      xmlns="https://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.4875 21.675H6.33745C4.23745 21.675 2.51245 19.95 2.51245 17.85C2.51245 16.2 3.56245 14.7375 5.09995 14.25V14.175C5.09995 12.2625 6.67495 10.6875 8.58745 10.6875C9.03745 10.6875 9.48745 10.7625 9.89995 10.9875C10.8 9.60002 12.375 8.77502 14.0625 8.77502C16.8 8.77502 19.0875 11.025 19.0875 13.8C19.0875 14.1 19.05 14.3625 19.0125 14.6625C20.7375 14.925 22.0875 16.3875 22.0875 18.1875C22.0125 20.0625 20.4375 21.675 18.4875 21.675ZM8.54995 11.775C7.23745 11.775 6.18745 12.825 6.18745 14.1375C6.18745 14.25 6.18745 14.4 6.22495 14.55L6.29995 15.075L5.77495 15.15C4.49995 15.4125 3.59995 16.5375 3.59995 17.8125C3.59995 19.3125 4.83745 20.55 6.33745 20.55H18.4875C19.8375 20.55 20.9625 19.425 20.9625 18.075C20.9625 16.725 19.8375 15.6 18.4875 15.6H18.375L17.55 15.6375L17.8125 14.85C17.925 14.475 18 14.0625 18 13.65C18 11.475 16.2375 9.75002 14.1 9.75002C12.675 9.75002 11.325 10.575 10.65 11.85L10.35 12.375L9.86245 12.075C9.44995 11.85 8.99995 11.775 8.54995 11.775Z"
        fill="var(--icon-cloud)"
      />
      <path
        d="M21.4874 7.42501C20.2499 7.42501 19.0874 6.86251 18.4874 5.96251C18 5.21251 17.8875 4.05001 18.2625 3.07501H18.15C17.1 3.07501 16.0875 3.45001 15.3375 4.20001C14.5875 4.95001 14.1375 5.96251 14.1375 7.01251C14.1375 7.16251 14.1375 7.31251 14.175 7.46251V7.61251H14.325C14.5125 7.61251 14.7375 7.65001 14.925 7.68751L15.15 7.72501L15.1125 7.50001C15.075 7.31251 15.075 7.16251 15.075 7.01251C15.075 5.73751 15.9 4.57501 17.0999 4.12501C17.0249 5.13751 17.2875 6.07501 17.9249 6.86251C18.5999 7.72501 19.6124 8.28751 20.8124 8.43751C20.4374 9.15001 19.9499 9.60001 19.3499 9.86251L19.125 9.93751L19.275 10.125C19.3875 10.275 19.4999 10.425 19.6124 10.575L19.6875 10.6875L19.7999 10.6125C20.9999 10.05 21.8249 8.92501 22.0124 7.61251L22.0499 7.42501H21.4874Z"
        fill="var(--icon-moon)"
      />
    </svg>
  );
}
