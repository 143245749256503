import * as React from "react";

export function Moon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="#000000"
      xmlns="https://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M18.9375 13.1625C14.625 13.1625 9.8625 9.75001 12.0375 4.20001C7.3875 4.16251 4.2 7.95001 4.2 12C4.2 16.3125 7.6875 19.8375 12.0375 19.8375C15.975 19.8375 19.2375 16.9875 19.8 13.2C19.8 13.1625 19.2375 13.1625 18.9375 13.1625ZM12.15 18.675C8.4375 18.675 5.4375 15.675 5.4375 11.9625C5.4375 8.88751 7.6125 6.30001 10.425 5.47501V5.51251C9.75 10.2 13.0875 14.1375 18.1875 14.5875C16.875 17.1375 15.075 18.5625 12.15 18.675Z" />
    </svg>
  );
}
