import * as React from "react";

export function Icon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="#000000"
      xmlns="https://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.56256 22.2375C9.48756 22.2375 9.45006 22.2375 9.37506 22.2C9.11256 22.0875 9.00006 21.7875 9.11256 21.5625L12.6001 13.35C12.7126 13.0875 13.0126 12.975 13.2376 13.0875C13.5001 13.2 13.6126 13.5 13.5001 13.725L10.0126 21.9375C9.93756 22.125 9.75006 22.2375 9.56256 22.2375V22.2375ZM12.7501 20.0625C12.6751 20.0625 12.6376 20.0625 12.5626 20.025C12.3001 19.9125 12.1876 19.6125 12.3001 19.3875L14.7751 13.3875C14.8876 13.125 15.1876 13.0125 15.4126 13.125C15.6751 13.2375 15.7876 13.5375 15.6751 13.7625L13.2001 19.7625C13.1251 19.95 12.9376 20.0625 12.7501 20.0625V20.0625ZM8.21256 20.0625C8.13756 20.0625 8.10006 20.0625 8.02506 20.025C7.76256 19.9125 7.65006 19.6125 7.76256 19.3875L10.2376 13.3875C10.3501 13.125 10.6501 13.0125 10.8751 13.125C11.1376 13.2375 11.2501 13.5375 11.1376 13.7625L8.66256 19.7625C8.58756 19.95 8.43756 20.0625 8.21256 20.0625V20.0625Z"
        fill="var(--icon-rain)"
      />
      <path
        d="M19.0875 8.39998C19.125 8.09998 19.1625 7.79998 19.1625 7.49998C19.1625 4.72498 16.9125 2.47498 14.1375 2.47498C12.45 2.47498 10.875 3.33748 9.9375 4.72498C9.4875 4.53748 9.0375 4.42498 8.55 4.42498C6.6375 4.42498 5.0625 5.99998 5.0625 7.91248V8.02498C3.525 8.54998 2.4375 9.97498 2.4375 11.6625C2.4375 13.7625 4.1625 15.4875 6.2625 15.4875H8.1375C8.7375 15.4875 8.7 14.475 8.1375 14.475H6.2625C4.6875 14.475 3.45 13.2 3.45 11.6625C3.45 10.3125 4.3875 9.14998 5.7375 8.88748L6.225 8.77498L6.15 8.28748C6.1125 8.13748 6.1125 8.02498 6.1125 7.87498C6.1125 6.52498 7.2375 5.39998 8.5875 5.39998C9.0375 5.39998 9.4875 5.51248 9.9 5.77498L10.35 6.03748L10.6125 5.54998C11.325 4.23748 12.675 3.41248 14.175 3.41248C16.3875 3.41248 18.1875 5.21248 18.1875 7.42498C18.1875 7.83748 18.1125 8.24998 18 8.66248L17.775 9.37498L18.5625 9.33748H18.675C20.1 9.33748 21.225 10.5 21.225 11.8875C21.225 13.3125 20.0625 14.4375 18.675 14.4375H16.5375C15.9 14.4375 15.7875 15.45 16.5375 15.45H18.675C20.625 15.45 22.2375 13.8375 22.2375 11.8875C22.2 10.125 20.85 8.62498 19.0875 8.39998V8.39998Z"
        fill="var(--icon-cloud)"
      />
    </svg>
  );
}
