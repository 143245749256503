import * as React from "react";

export function Icon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="#000000"
      xmlns="https://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.6376 14.4375C14.4376 14.4375 13.3501 14.1 12.3751 13.425C9.67505 11.5125 7.42505 11.4375 5.02505 13.0875C4.83755 13.2 4.61255 13.1625 4.50005 12.975C4.38755 12.7875 4.42505 12.5625 4.61255 12.45C7.27505 10.6125 9.90005 10.725 12.8251 12.7875C15.1501 14.4375 17.8126 13.5 19.6501 12.4125C19.8376 12.3 20.0626 12.375 20.1751 12.5625C20.2876 12.75 20.2126 12.975 20.0251 13.0875C18.5251 13.9875 17.0251 14.4375 15.6376 14.4375V14.4375Z"
        fill="var(--icon-cloud)"
      />
      <path
        d="M15.6376 17.3625C14.4376 17.3625 13.3501 17.025 12.3751 16.35C9.67505 14.4375 7.42505 14.3625 5.02505 16.0125C4.83755 16.125 4.61255 16.0875 4.50005 15.9C4.38755 15.7125 4.42505 15.4875 4.61255 15.375C7.27505 13.5375 9.90005 13.65 12.8251 15.7125C15.1501 17.325 17.8126 16.425 19.6501 15.3375C19.8376 15.225 20.0626 15.3 20.1751 15.4875C20.2876 15.675 20.2126 15.9 20.0251 16.0125C18.5251 16.9125 17.0251 17.3625 15.6376 17.3625V17.3625ZM15.6376 11.55C14.4376 11.55 13.3501 11.2125 12.3751 10.5375C9.67505 8.62497 7.42505 8.54997 5.02505 10.2C4.83755 10.3125 4.61255 10.275 4.50005 10.0875C4.38755 9.89997 4.42505 9.67497 4.61255 9.56247C7.27505 7.72497 9.90005 7.83747 12.8251 9.89997C15.1501 11.55 17.8126 10.6125 19.6501 9.52497C19.8376 9.41247 20.0626 9.48747 20.1751 9.67497C20.2876 9.86247 20.2126 10.0875 20.0251 10.2C18.5251 11.1 17.0251 11.55 15.6376 11.55V11.55Z"
        fill="var(--icon-cloud)"
      />
    </svg>
  );
}
