import * as React from "react";

export function Icon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="#000000"
      xmlns="https://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.38749 19.875C7.31249 19.875 7.23749 19.875 7.16249 19.8375C6.89999 19.725 6.82499 19.425 6.93749 19.1625L7.98749 16.9875C8.09999 16.725 8.39999 16.65 8.66249 16.7625C8.92499 16.875 8.99999 17.175 8.88749 17.4375L7.83749 19.6125C7.72499 19.7625 7.57499 19.875 7.38749 19.875ZM4.91249 19.875C4.83749 19.875 4.76249 19.875 4.68749 19.8375C4.42499 19.725 4.34999 19.425 4.46249 19.1625L5.51249 16.9875C5.62499 16.725 5.92499 16.65 6.18749 16.7625C6.44999 16.875 6.52499 17.175 6.41249 17.4375L5.36249 19.6125C5.28749 19.7625 5.09999 19.875 4.91249 19.875V19.875ZM17.55 19.875C17.475 19.875 17.4 19.875 17.325 19.8375C17.0625 19.725 16.9875 19.425 17.1 19.1625L18.15 16.9875C18.2625 16.725 18.5625 16.65 18.825 16.7625C19.0875 16.875 19.1625 17.175 19.05 17.4375L18 19.6125C17.925 19.7625 17.7375 19.875 17.55 19.875ZM15.075 19.875C15 19.875 14.925 19.875 14.85 19.8375C14.5875 19.725 14.5125 19.425 14.625 19.1625L15.675 16.9875C15.7875 16.725 16.0875 16.65 16.35 16.7625C16.6125 16.875 16.6875 17.175 16.575 17.4375L15.525 19.6125C15.45 19.7625 15.2625 19.875 15.075 19.875V19.875Z"
        fill="var(--icon-rain)"
      />
      <path
        d="M10.3875 22.2375C10.3125 22.2375 10.2 22.2 10.125 22.1625C9.89995 22.0125 9.82495 21.7125 9.97495 21.4875L12.9375 16.575H9.82495L13.3125 11.625C13.4625 11.4 13.7625 11.3625 13.9875 11.5125C14.2125 11.6625 14.2499 12 14.0999 12.225L11.7 15.6375H14.6624L10.8 21.975C10.725 22.125 10.5375 22.2375 10.3875 22.2375V22.2375Z"
        fill="var(--icon-orange)"
      />
      <path
        d="M19.0875 8.3625C19.125 8.0625 19.1625 7.7625 19.1625 7.4625C19.1625 4.6875 16.9125 2.4375 14.1375 2.4375C12.45 2.4375 10.875 3.3 9.93748 4.6875C9.52498 4.5 9.03748 4.3875 8.58748 4.3875C6.67498 4.3875 5.09998 5.9625 5.09998 7.875V7.9875C3.56248 8.5125 2.47498 9.9375 2.47498 11.625C2.47498 13.725 4.19998 15.45 6.29998 15.45H8.17498C8.77498 15.45 8.73748 14.475 8.17498 14.475H6.29998C4.72498 14.475 3.48748 13.2 3.48748 11.6625C3.48748 10.3125 4.42498 9.15 5.77498 8.8875L6.26248 8.8125L6.18748 8.325C6.14998 8.175 6.14998 8.025 6.14998 7.9125C6.14998 6.5625 7.27498 5.4375 8.62498 5.4375C9.07498 5.4375 9.52498 5.55 9.93748 5.8125L10.3875 6.075L10.65 5.5875C11.3625 4.275 12.7125 3.45 14.2125 3.45C16.425 3.45 18.225 5.25 18.225 7.4625C18.225 7.875 18.15 8.2875 18.0375 8.7L17.7375 9.375L18.525 9.3375H18.6375C20.0625 9.3375 21.1875 10.5 21.1875 11.8875C21.1875 13.3125 20.025 14.4375 18.6375 14.4375H16.5C15.8625 14.4375 15.75 15.4125 16.5 15.4125H18.6375C20.5875 15.4125 22.2 13.8 22.2 11.85C22.2 10.0875 20.8125 8.5875 19.0875 8.3625V8.3625Z"
        fill="var(--icon-cloud)"
      />
    </svg>
  );
}
